import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField } from "@mui/material";
import {
  DateCalendar,
  DateTimePicker,
  StaticDatePicker,
  StaticDateTimePicker,
  StaticTimePicker,
  TimeClock,
  TimePicker
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function DateComponent({
  data,
  handledatechange,
  datavalue,
  datemod,
  datatextvalue,
  formaction
}) {

  const dateformat = (date, dateFormat) => {
    const dateArray = date.split("-");
    const [dd, mm, yyyy] = dateArray;
    if (dateFormat === "DD-MM-YYYY") {
      return `${dd.length == "1" ? "0" + dd : dd}-${mm.length == "1" ? "0" + mm : mm}-${yyyy}`;
    }
    if (dateFormat === "YYYY-DD-MM") {
      return `${yyyy}-${dd.length == "1" ? "0" + dd : dd}-${mm.length == "1" ? "0" + mm : mm}`;
    }
    if (dateFormat === "YYYY-MM-DD") {
      return `${yyyy}-${mm.length == "1" ? "0" + mm : mm}-${dd.length == "1" ? "0" + dd : dd}`;
    }
  };

  const handleDate = event => {
    const item_date = `${event.$D}-${event.$M + 1}-${event.$y}`;
    const formatedDate = dateformat(item_date, data?.component?.sSendFormat);
    alert(formatedDate)
    handledatechange(formatedDate);
  };

  // console.log(data?.component?.sSendFormat =='YYYY-MM-DD','sSendFormat');

  return (
    // <Grid container spacing={2}>
    <Grid item>
        {/* {datavalue} */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {
            <DemoContainer
              components={[
                "DatePicker",
                "TimePicker",
                "StaticDatePicker",
                "DateCalendar",
                "DateCalendar",
                "StaticTimePicker",
                "TimeClock",
                "DateTimePicker",
                "StaticDateTimePicker"
              ]}
            >
              {data?.component?.options?.mode === "DATE" &&
              data?.component?.options?.submode === "DatePicker" ? (
                <>
                  {formaction == undefined || formaction != "EDIT" ? (
                    <>
                      <DatePicker
                        id={data?.component?.sName}
                        // value={datavalue}
                        {...data?.component?.options?.others1}
                        {...data?.component?.options?.others2}
                        {...data?.component?.options?.others3}
                        
                        slots={{
                          textField: (params) => (
                            <TextField
                              {...params}
                              id={data?.component?.sName}
                            />
                          ),
                        }}
                  
                        label={data?.component?.sLabel}
                        // defaultValue={pselectDate}
                        value={
                           datavalue && datavalue !== undefined
                            ? dayjs(datavalue)
                            : dayjs(data?.component?.sDefaultValue)
                        }
                        onChange={e => handleDate(e)}
                        //  format={data?.component?.sSendFormat}
                        {...data?.component?.options?.others1}
                        {...data?.component?.options?.others2}
                        {...data?.component?.options?.others3}
                        {...data?.component?.sProps}
                      />
                    </>
                  ) : null}

                  {formaction === "EDIT" &&
                  datavalue &&
                  datemod === "FREEFORM" &&
                  datavalue !== undefined ? (
                    <>
                      <DatePicker
                        id={data?.component?.sName}
                        label={data?.component?.sLabel}
                        defaultValue={dayjs(datavalue)}
                        onChange={e => handleDate(e)}
                        slotProps={{
                          textField: {
                            helperText: data?.component?.sHelper
                          }
                        }}
                        slots={{
                          textField: (params) => (
                            <TextField
                              {...params}
                              id={data?.component?.sName}
                            />
                          ),
                        }}
                        {...data?.component?.options?.others1}
                        {...data?.component?.options?.others2}
                        {...data?.component?.options?.others3}
                        {...data?.component?.sProps}
                      />
                    </>
                  ) : formaction === "EDIT" &&
                    datavalue &&
                    datemod === "DEFAULT" &&
                    datavalue !== undefined ? (
                    <>
                      <DatePicker
                        id={data?.component?.sName}
                        label={data?.component?.sLabel}
                        defaultValue={dayjs(datavalue)}
                        onChange={e => handleDate(e)}
                        slotProps={{
                          textField: {
                            helperText: data?.component?.sHelper
                          }
                        }}
                        slots={{
                          textField: (params) => (
                            <TextField
                              {...params}
                              id={data?.component?.sName}
                            />
                          ),
                        }}
                        {...data?.component?.options?.others1}
                        {...data?.component?.options?.others2}
                        {...data?.component?.options?.others3}
                        {...data?.component?.sProps}
                      />
                    </>
                  ) : (
                    formaction === "EDIT" &&
                    datatextvalue &&
                    datatextvalue !== "checked" && (
                      <>
                        <DatePicker
                          id={data?.component?.sName}
                          label={data?.component?.sLabel}
                          defaultValue={dayjs(datatextvalue)}
                          onChange={e => handleDate(e)}
                          slotProps={{
                            textField: {
                              helperText: data?.component?.sHelper
                            }
                          }}
                          slots={{
                            textField: (params) => (
                              <TextField
                                {...params}
                                id={data?.component?.sName}
                              />
                            ),
                          }}
                          {...data?.component?.options?.others1}
                          {...data?.component?.options?.others2}
                          {...data?.component?.options?.others3}
                          {...data?.component?.sProps}
                        />
                      </>
                    )
                  )}
                </>
              ) : data?.component?.options?.mode === "DATE" &&
                data?.component?.options?.submode === "StaticDatePicker" ? (
                <StaticDatePicker
                  label={data?.component?.sLabel}
                  defaultValue={data?.component?.sDefaultValue}
                  onChange={e => handleDate(e)}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.options?.others2}
                  {...data?.component?.sProps}
                />
              ) : data?.component?.options?.mode === "DATE" &&
                data?.component?.options?.submode === "DateCalendar" ? (
                <DateCalendar
                  label={data?.component?.sLabel}
                  //   defaultValue={data?.component?.sDefaultValue}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  onChange={e => handleDate(e)}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.options?.others2}
                  {...data?.component?.sProps}
                />
              ) : data?.component?.options?.mode === "TIME" &&
                data?.component?.options?.submode === "TimePicker" ? (
                <TimePicker
                  label={data?.component?.sLabel}
                  //   defaultValue={dayjs(data?.component?.sDefaultValue)}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  onChange={e => handleDate(e)}
                  {...data?.component?.options?.others2}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.sProps}
                />
              ) : data?.component?.options?.mode === "TIME" &&
                data?.component?.options?.submode === "StaticTimePicker" ? (
                <StaticTimePicker
                  label={data?.component?.sLabel}
                  //   defaultValue={dayjs(data?.component?.sDefaultValue)}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  onChange={e => handleDate(e)}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.options?.others2}
                  {...data?.component?.sProps}
                />
              ) : data?.component?.options?.mode === "TIME" &&
                data?.component?.options?.submode === "TimeClock" ? (
                <TimeClock
                  label={data?.component?.sLabel}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.options?.others2}
                  defaultValue={dayjs(data?.component?.sDefaultValue)}
                  onChange={e => handleDate(e)}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  {...data?.component?.sProps}
                />
              ) : data?.component?.options?.mode === "DATETIME" &&
                data?.component?.options?.submode === "DateTimePicker" ? (
                <DateTimePicker
                  label={data?.component?.sLabel}
                  id={data?.component?.sName}
                  //   defaultValue={dayjs(data?.component?.sDefaultValue)}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  onChange={e => handleDate(e)}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.options?.others2}
                  {...data?.component?.sProps}
                />
              ) : data?.component?.options?.mode === "DATETIME" &&
                data?.component?.options?.submode === "StaticDateTimePicker" ? (
                <StaticDateTimePicker
                  label={data?.component?.sLabel}
                  defaultValue={dayjs(data?.component?.sDefaultValue)}
                  slotProps={{
                    textField: {
                      helperText: data?.component?.sHelper
                    }
                  }}
                  onChange={e => handleDate(e)}
                  {...data?.component?.options?.others1}
                  {...data?.component?.options?.others3}
                  {...data?.component?.options?.others2}
                  {...data?.component?.sProps}
                />
              ) : null}
            </DemoContainer>
          }
        </LocalizationProvider>
        {/* // </Grid> */}
      </Grid>
  );
}
