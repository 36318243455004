import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverAddress } from '../../config';
import { Global_Data } from '../../globalData/GlobalData';

function EVENT({data,setTextValue, setdocumentSelectTableData,setdocumentSelectmappingData}) {
  const { token,textValue } = Global_Data();
  const [textValeResponse,setTextValueResponse] =useState({})

  function replaceUrlPlaceholders(actualUrl, pattern) {
    const url = new URL(actualUrl);
    const params = new URLSearchParams(url.search);
    // Iterate over each query parameter and replace the corresponding placeholders in the pattern
    params.forEach((value, key) => {
        const placeholder = `[${key}]`;
        pattern = pattern.replace(placeholder, value);
    });

    return pattern;
}

// Example usage
const actualUrl = window.location.href;
const pattern = data.data.sDataSource;
const result = replaceUrlPlaceholders(actualUrl, pattern);
// console.log(result); // Output: record/get/transaction/document/sal_salesquote/00000006

  const getoptions = () => {
    axios
      .get(serverAddress + result , {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .then(res => {
        
        setTextValue(res.data.data.mainrecord)

        const newTable = res.data.data.tablerecords.map((record) => ({...record,mappingTableName:data?.data?.mapping?.tablerecord[0]?.tablenameDestination}))
        setdocumentSelectTableData(newTable)


        const obj ={}
           for (let i = 0; i < data?.data?.mapping?.tablerecord[0]?.mapping.length; i++) {
               const element = data?.data?.mapping?.tablerecord[0]?.mapping[i];
               obj[element.destinationColumn] =  element.sourceXPath.split('.')[1]
           }
           setdocumentSelectmappingData(obj)

      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    getoptions();
  }, []);

  return (
    <div>
      
      {/* {JSON.stringify(data?.data?.mapping)} */}
      </div>
  )
}

export default EVENT