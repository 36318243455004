import { Grid } from '@mui/material';
import React from 'react'

function VarDateTime({data,value}) {

    function formatDate(value, displayFormat) {
        const date = new Date(value);
    
        const options = {
            'MMMM Do YYYY, h:mm:ss a': { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true },
            'dddd': { weekday: 'long' },
            'MMM Do YY': { month: 'short', day: 'numeric', year: '2-digit' },
            'YYYY [escaped] YYYY': { year: 'numeric' },
            'MM-DD-YYYY': { month: '2-digit', day: '2-digit', year: 'numeric' },
            'DD/MM/YYYY': { day: '2-digit', month: '2-digit', year: 'numeric' },
            'LT': { hour: 'numeric', minute: 'numeric', hour12: true },
            'LTS': { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true },
            'L': { month: '2-digit', day: '2-digit', year: 'numeric' },
            'l': { month: 'numeric', day: 'numeric', year: 'numeric' },
            'LL': { month: 'long', day: 'numeric', year: 'numeric' },
            'll': { month: 'short', day: 'numeric', year: 'numeric' },
            'LLL': { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true },
            'lll': { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true },
            'LLLL': { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true },
            'llll': { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true },
        };
    
        if (displayFormat === 'YYYY [escaped] YYYY') {
            return `${date.getFullYear()} escaped ${date.getFullYear()}`;
        } else if (options[displayFormat]) {
            return date.toLocaleDateString('en-US', options[displayFormat]);
        } else {
            return 'Invalid format';
        }
    }
    

    
  return (
    <Grid item>
        
        <span dangerouslySetInnerHTML={{__html:value? formatDate(value,data?.inputType?.component?.sDisplayFormat):""}}/>
        </Grid>
  )
}

export default VarDateTime