  import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import React, { memo, useEffect } from "react";
import { Global_Data } from "../../globalData/GlobalData";

function DailogMain({ data, open, renderComponent }) {
  const { setOpenModalChildData ,setopenModalDataActive} = Global_Data();

  useEffect(() => {
    if (open) {
      
        setopenModalDataActive(data.component);
      // setopenModalDataActive(data.component);
      setOpenModalChildData(data?.child);
    }else{
      setOpenModalChildData([]);
      setopenModalDataActive({});
    }
  }, [open]);

  return (
    <>
    {/* <Grid container>
      <Dialog
        open={open}
        maxWidth={
          data.component.sName === "payment_terms_dialog" ||
          data.component.sName === "control_number_dialog"
          ? "lg"
          : "sm"
        }
        aria-labelledby={`${data?.component?.sName}-alert-dialog-title`}
        style={{ zIndex: "1201" }}
        aria-describedby={`${data?.component?.sName}-alert-dialog-description`}
        >
        <DialogTitle id={`${data?.component?.sType}-alert-dialog-title`}>
          {data?.component?.sLabel}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={data?.component?.sName}>
            {data?.component?.sDefaultValue}
          </DialogContentText>
          <Grid item {...data?.grid_props}>
      
            <Grid container>{renderComponent(data?.child)}</Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid> */}
    <Grid container>
<Dialog
open={open}
aria-labelledby={`${data?.component?.sName}-alert-dialog-title`}
style={{ zIndex: "1201" }}
aria-describedby={`${data?.component?.sName}-alert-dialog-description`}
{...data?.props}
>
<DialogTitle id={`${data?.component?.sType}-alert-dialog-title`}>
{data?.component?.sLabel}
</DialogTitle>
<DialogContent>
<DialogContentText id={data?.component?.sName}>
{data?.component?.sDefaultValue}
</DialogContentText>
<Grid item >
<Grid container>{renderComponent(data?.child)}</Grid>
</Grid>
</DialogContent>
</Dialog>
</Grid>
        </>
  );
}

export default memo(DailogMain);
