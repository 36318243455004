import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { serverAddress } from "../../config";
function SingleImage({
  setTextValue,
  textValue,
  imagesIdsFromMedia,
  setImagesIdsFromMedia,
  data,value,
  openStaticDialog
}) {
  const { token } = Global_Data();

  const [images, setImages] = useState([]);

  const GetImg = async images => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const updImg = [];
    if (images?.length > 0) {
      if (data?.component?.options?.mode == "SINGLE") {
        try {
          const response = await fetch(
            serverAddress+`/image/retrieve?imageid=${images[0]}`,
            requestOptions
          );
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);

          updImg.push({ src: objectURL });
          // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
        } catch (error) {
          console.error("Error fetching image:", error);
          throw error; // Rethrow the error for handling elsewhere if needed
        }
        setTextValue(pre => ({ ...pre, [data?.component?.sName]: images[0] }));
      } else {
        for (let item of images) {
          try {
            const response = await fetch(
              serverAddress+`/image/retrieve?imageid=${item}`,
              requestOptions
            );
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);

            updImg.push({ src: objectURL });
            // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
          } catch (error) {
            console.error("Error fetching image:", error);
            throw error; // Rethrow the error for handling elsewhere if needed
          }
        }
        setTextValue(pre => ({ ...pre, [data?.component?.sName]: images.join(", ") }));
      }
    }

    // After all images are fetched and updated, set the state
    setImages(updImg);
  };
  function getObjectBySName(name,data) {

    for (let i = data?.length - 1; i >= 0; i--) {
      if (data[i].sName === name) {
          return data[i];
      }
  }
  return null; // Return null if no matching object is found
}
  useEffect(() => {
    const cdata = getObjectBySName(data?.component?.sName,imagesIdsFromMedia) 
    if (cdata) {
      GetImg(cdata?.data);
    }
  }, [imagesIdsFromMedia]);
  useEffect(() => {
    // setTextValue((pre)=>({ ...pre, [data?.component?.sName]:'' }));
    if (!textValue[data?.component?.sName]) {
      textValue[data?.component?.sName] = "";
    }
  }, [textValue]);

  function removeImages() {
    setImages([]);
    setImagesIdsFromMedia([]);
    setTextValue(pre => ({ ...pre, [data?.component?.sName]: "" }));
  }
  return (
    <Grid item {...data?.grid_props}>
      
      {/* {JSON.stringify(imagesIdsFromMedia)} */}
      {images?.length !== 0 ? (
        <Box {...data?.component?.sPrimaryContainer?.sProps}>
          {images?.map(image => (
            <img
              style={{ height: "-webkit-fill-available", width: "-webkit-fill-available" }}
              src={image.src}
            />
          ))}
        </Box>
      ) : (
        <Box
          {...data?.component?.sPrimaryContainer?.sProps}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <PhotoCameraIcon style={{ fontSize: "50px" }} />
        </Box>
      )}
      <Box
        marginTop={"10px"}
        justifyContent={"space-between"}
        display={"flex"}
        alignItems={"center"}
      >
        <Button
          onClick={() => openStaticDialog(true, data?.component?.sName)}
          {...data?.component?.sSelectButton?.sProps}
        >
          {data?.component?.sSelectButton?.sLabel}
        </Button>
        <Button onClick={() => removeImages()} {...data?.component?.sRemoveButton?.sProps}>
          {data?.component?.sRemoveButton?.sLabel}
        </Button>
      </Box>
    </Grid>
  );
}

export default SingleImage;
