import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";

function Varselect({ ind, data, handleclickdata, error }) {
  const { textValue } = Global_Data();
  const [selectedValue, setselectedValue] = useState("");
  const [selectedValueChange, setselectedValueChange] = useState("");
  const options = Object.entries(data?.component?.sPatterns || {}).map(([key, pattern]) => ({
    value: key,
    label: pattern.replace(/{(\w+)}/g, (match, prop) => textValue[prop]),
  }));


  useEffect(() => {
    // setselectedValue(textValue[data?.component?.sName] || "");
if (selectedValueChange == '') {
  textValue[data.component.sName]=options[0].label
  setselectedValue(options[0].label || "");
}
  }, [textValue]);

  // useEffect(()=>{
  //   const e = {
  //     target: {
  //       name: data.component.sName,
  //       value: selectedValue === 'def' ? "" :options[0].label,
  //     },
  //   };
  //   handleclickdata(e, ind);
  // },[[selectedValue]])
  // useEffect(() => {
  //   setselectedValue(textValue[data?.component?.sName] || "");
  //   // setselectedValue(options[0].label || "");
  // }, [textValue, data?.component?.sName]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setselectedValue(event.target.value)
    setselectedValueChange(event.target.value)
    const e = {
      target: {
        name: data.component.sName,
        value: selectedValue === 'def' ? "" : selectedValue,
      },
    };
    handleclickdata(e, ind);
  };

 

  return (
    <Grid item {...data?.grid_props}  >
      <FormControl fullWidth  error={error && error[data?.component?.sName]}>
        {/* <InputLabel>{data?.component?.sPlaceHolder}</InputLabel> */}
        <Select
          value={selectedValue || ""}
          {...data?.component?.sProps}
        
          name={data?.component?.sName}
          id={data?.component?.sName}
          {...data?.component?.options?.others1}
          onChange={(event) => handleChange(event)}
        >
            <MenuItem value={""}>
            {data?.component?.sPlaceHolder}
            </MenuItem>

          {options.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Typography className="text-red-500">{error && error[data?.component?.sName]}</Typography>
      </FormControl>
    </Grid>
  );
}

export default Varselect;
