import * as React from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StarIcon from "@mui/icons-material/Star";
import { serverAddress } from "../../config";
function MultipleImage({ textValue,featuredImgIDs, imagesIdsFromMedia, setImagesIdsFromMedia, data }) {
  const { token } = Global_Data();
  const [featuredImage, setfeaturedImage] = useState({});
  useEffect(() => {setfeaturedImage(featuredImgIDs)},[featuredImgIDs])
  // console.log(imagesIdsFromMedia);
  const [images, setImages] = useState([]);

  const GetImg = async images => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const updImg = [];
    if (images?.length > 0) {
      if (data?.component?.options?.mode == "SINGLE") {
        try {
          const response = await fetch(
          serverAddress + `/image/retrieve?imageid=${images[0]}`,
            requestOptions
          );
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);

          updImg.push({ src: objectURL, imgID: images[0] });
          // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
        } catch (error) {
          console.error("Error fetching image:", error);
          throw error; // Rethrow the error for handling elsewhere if needed
        }
      } else {
        for (let item of images) {
          try {
           setTimeout( async () => {
            const response = await fetch(
              serverAddress + `/image/retrieve?imageid=${item}`,
               requestOptions
             );
             const blob = await response.blob();
             const objectURL = URL.createObjectURL(blob);
             updImg.push({ src: objectURL, imgID: item });
             // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
           }, 300);
          } catch (error) {
            console.error("Error fetching image:", error);
            throw error; // Rethrow the error for handling elsewhere if needed
          }
        }
        
        let valueString = "";
        for (let i = 0; i < images.length; i++) {
          if (featuredImage.imgID == images[i]) {
            valueString += images[i] + ":Featured, ";
          } else {
            valueString += images[i] + ", ";
          }
        }
      }
    }
    // After all images are fetched and updated, set the state
    setImages(updImg);
  };
  function getObjectBySName(name, data) {
    for (let i = data?.length - 1; i >= 0; i--) {
      if (data[i].sName === name) {
        return data[i];
      }
    }
    return null; // Return null if no matching object is found
  }
  useEffect(() => {
    const cdata = getObjectBySName(data?.component?.sName, imagesIdsFromMedia);
    if (cdata) {
      GetImg(cdata?.data);
    }
  }, [imagesIdsFromMedia]);
  // useEffect(() => {
  //   // setTextValue((pre)=>({ ...pre, [data?.component?.sName]:'' }));
  //   if (!textValue[data?.component?.sName]) {
  //     textValue[data?.component?.sName] = "";
  //   }
  // }, [textValue]);
  const [removeImageIds, setRemoveImageIds] = useState([]);

  return (
    <Grid item {...data?.grid_props}>
      <Grid display={"flex"} gap={"20px"}>
        {!featuredImage.src ? (
          <Grid
            {...data?.component?.sPrimaryContainer?.sProps}
            style={{ border: "1px dashed gray", height: "450px", width: "500px" }}
          >
            <Box
              sx={{ width: 500, height: 450, overflowY: "scroll" }}
              justifyContent={"center"}
              display={"flex"}
              alignItems={"center"}
            >
              <PhotoCameraIcon style={{ fontSize: "50px" }} />
            </Box>
          </Grid>
        ) : (
          <Grid
            {...data?.component?.sPrimaryContainer?.sProps}
            style={{ border: "1px dashed gray", height: "450px", width: "500px" }}
          >
            <FeaturedImage featuredImage={featuredImage.src} img={images} />
          </Grid>
        )}
        <Grid
          {...data?.component?.sSecondaryContainer?.sProps}
          style={{ border: "1px dashed gray", height: "450px", width: "150px" }}
        >
          <CustomImageList
            data={data}
            removeImageIds={removeImageIds}
            setRemoveImageIds={setRemoveImageIds}
            featuredImage={featuredImage}
            setfeaturedImage={setfeaturedImage}
            itemData={images}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MultipleImage;

function FeaturedImage({ img, featuredImage }) {
  return (
    <>
      <Box sx={{ width: 500, height: 450, overflowY: "scroll" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: " -webkit-fill-available"
          }}
        >
          {/* <ImageList cols={3} gap={8} > */}
          <ImageList>
            {/* {img.map(item => ( */}
            <ImageListItem>
              <img
                srcSet={`${featuredImage}`}
                src={`${featuredImage}`}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                  margin: 0
                }}
                // alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar position="below" />
            </ImageListItem>
            {/* ))} */}
          </ImageList>
        </div>
      </Box>
    </>
  );
}

function CustomImageList({
  removeImageIds,
  setRemoveImageIds,
  data,
  itemData,
  setfeaturedImage,
  featuredImage
}) {
  itemData.forEach(element => {
    element["featured"];
  });

  return (
    <>
      <ImageList
        {...data?.component?.sSecondaryContainer?.sProps}
        sx={{
          width: 150,
          height: 450,

          transform: "translateZ(0)"
        }}
        rowHeight={80}
        gap={1}
      >
        {itemData.map(item => {
          const cols = 2;
          const rows = 2;

          return (
            <ImageListItem key={item.img} cols={cols} rows={rows}>
              <img onClick={() => setfeaturedImage({ src: item.src, imgID: item.imgID })} src={item.src} loading="lazy" style={{cursor:"pointer", height: "150px", width: "150px" }} />

              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                }}
                title={item.title}
                position="top"
                actionIcon={
                  featuredImage.imgID == item.imgID ? (
                    <IconButton
                      onClick={() => setfeaturedImage({ src: item.src, imgID: item.imgID })}
                      sx={{ color: "white" }}
                    >
                      <StarIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => setfeaturedImage({ src: item.src, imgID: item.imgID })}
                      sx={{ color: "white" }}
                    >
                      <StarBorderIcon />
                    </IconButton>
                  )
                }
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </>
  );
}
